<template>

    <div style="height:100%">


        <v-layout row wrap style="height:100%">



            <v-flex xs12 md7 sm7 class="pdr">

                <v-card style="padding:45px;" flat>
                    <h1 style="text-align:right;f">اهلا وسهلا بكم في منصه تكتاتي</h1>

                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <div>
                        <div style="color:#504a4a;font-size:18px;font-weight:bold">
                            يرجئ كتابه رقم الهاتف لاعاده تعين الباسورد ، سيتم ارسال رمز التحقق برساله

                        </div>
                        <!-- <div style="padding-top:7px;">
                           
                        </div> -->

                        <div style="padding-top:7px;">
                            للعوده لصفحه تسجيل الدخول
                            <router-link to="/LoginOwner" text small color="red"
                                style="font-weight:bold;font-size:18px">
                                اضغط هنا

                            </router-link>

                        </div>



                        <div v-if="!changePassword">
                            <v-form ref="form_signin" v-model="valid">
                                <v-layout row wrap>

                                    <v-flex xs12 md12 sm12 pt-5>
                                        <v-alert type="error" v-if="error_msg.length!==0"
                                            style="position: relative;right: 0%;width: 84%;height:50px">

                                            <span style="font-family:'droid'!important;" v-for="error in error_msg"
                                                :key="error">{{error}}</span>
                                        </v-alert>


                                    </v-flex>



                                    <v-flex xs12 md8 sm8>
                                        <v-lable>رقم الهاتف</v-lable>
                                        <v-text-field filled v-model="user.phone" placeholder="رقم الهاتف"
                                            data-vv-name="name" height="50" type="number" :rules="phoneRules" required>
                                        </v-text-field>
                                    </v-flex>



                                    <v-flex xs12 md8 sm8>
                                        <v-btn class="reg_owner_btn" @click="submit" color="red"
                                            style="color:#fff;font-weight:bold;font-size:19px;"
                                            v-bind:class="{btn_active:signin}" height="60">
                                            ارسال</v-btn>

                                    </v-flex>

                                    <!-- </v-flex> -->

                                </v-layout>
                            </v-form>
                        </div>




                        <div v-if="changePassword">
                            <v-form ref="form_signin" v-model="valid">
                                <v-layout row wrap>

                                    <v-flex xs12 md12 sm12 pt-5>

                                        <v-alert type="error" v-if="error_msg.length!==0"
                                            style="position: relative;right: 0%;width: 84%;">

                                            <span v-for="error in error_msg" :key="error">{{error}}</span>
                                        </v-alert>


                                    </v-flex>

                                    <v-flex xs12 md10 sm10>
                                        <v-lable>يرجئ كتابه رمز التحقق تم ارساله برساله</v-lable>
                                        <v-text-field filled height="50" style="" placeholder="رمز التحقق"
                                            v-model="owners.verfy_code" required counter-value="4" type="number"
                                            :rules="verfyRules" autocomplete="off"></v-text-field>
                                    </v-flex>



                                    <v-flex xs12 md10 sm10>
                                        <v-lable>الباسورد</v-lable>
                                        <v-text-field filled v-model="owners.password" height="50"
                                            :type="show1 ? 'text' : 'password'"
                                            :append-icon="show1 ? 'visibility' : 'visibility_off'"
                                            @click:append="show1 = !show1" style="" placeholder="الباسورد" required
                                            :rules="passwordRules">
                                        </v-text-field>
                                    </v-flex>

                                    <v-flex xs12 md10 sm10>
                                        <v-lable>اعاده كتابه الباسورد</v-lable>
                                        <v-text-field filled :type="show2 ? 'text' : 'password'"
                                            :append-icon="show2 ? 'visibility' : 'visibility_off'"
                                            @click:append="show2 = !show2" v-model="owners.password_confirmation"
                                            height="50" style="" placeholder="اعاده كتابه الباسورد" required
                                            :rules="passwordConfirmationRules"></v-text-field>
                                    </v-flex>





                                    <v-flex xs12 md8 sm8>
                                        <v-btn class="reg_owner_btn" @click="changesPassword()" color="red"
                                            style="color:#fff;font-weight:bold;font-size:19px;"
                                            v-bind:class="{btn_active:signin}" height="60">
                                            ارسال</v-btn>

                                    </v-flex>

                                    <!-- </v-flex> -->

                                </v-layout>
                            </v-form>
                        </div>


                    </div>





                    <v-row>
                        <v-text-field color="primary" loading disabled v-show="loading"></v-text-field>
                    </v-row>

                    <p style="color:red;text-align:center">{{this.errors}}</p>


                </v-card>

            </v-flex>


            <v-flex xs12 class="hidden-md-and-down" md5 sm5>
                <v-layout column class="search_component" style="height:100%;background-color: #F2C98A !important;">

                    <div style="height: 100%;
    padding-top: 45px;

    position: fixed;
    bottom: 0;

    float: left;
    width: 40%;">


                        <v-layout row wrap style="">

                            <v-flex xs5></v-flex>

                            <v-flex xs2>


                                <v-img src="/assets/logo2.png" style="      height: 100%;
    width: 100%;"></v-img>




                            </v-flex>


                            <v-flex xs5></v-flex>

                        </v-layout>
                        <h2
                            style="padding-top: 15px;width: 100%;text-align:center;font-weight:bold;font-size:30px;padding-right:15px;color:#986923;">
                            منصه تكتاتي</h2>

                        <v-row>
                            <div
                                style="width: 100%;text-align:center;color:#986923;font-size:20px;font-weight:bold;padding-top:10px;text-align: center;">
                                حجوزات طبيه سياحيه مطاعم فنادق رياضه
                            </div>


                        </v-row>
                        <br>
                        <br>






                        <v-layout row wrap style="padding-top: 40px;">

                            <v-flex xs2></v-flex>

                            <v-flex xs8>


                                <v-img src="/assets/3899624.png" style="with:100%;height:100%"></v-img>




                            </v-flex>


                            <v-flex xs2></v-flex>

                        </v-layout>






                    </div>



                </v-layout>
            </v-flex>
        </v-layout>

        <v-dialog v-model="verfy_Dailog" max-width="450px" height="300px" persistent>
            <v-card>
                <v-form v-model="active_form" ref="active_form">
                    <v-container>
                        <span class="mb-4">تم ارسال رقم التفعيل برساله يرجئ التحقق</span>
                        <v-text-field label="ادخل رقم التفعيل" v-model="verfy_code" required counter-value="4"
                            type="number" :rules="verfyRules" autocomplete="off" class="mb-6">
                        </v-text-field>

                        <v-row justify="center">
                            <v-flex xs4 pl-3>
                                <v-btn color="success" @click="verfyAccount" style=";color:#fff;width:100%">
                                    تفعيل
                                </v-btn>



                            </v-flex>

                            <v-flex xs4>

                                <v-btn color="red" @click="verfy_Dailog=false" style=";color:#fff;width:100%">
                                    خروج
                                </v-btn>


                            </v-flex>

                        </v-row>
                    </v-container>

                </v-form>
            </v-card>
        </v-dialog>





    </div>


</template>

<style scoped>
    .v-content {
        padding: 0px 0px 0px 0px !important;
    }
</style>


<script>
    const axios = require('axios');
    export default {
        $_veeValidate: {
            validator: 'new',
        },
        name: 'app',
        data: () => ({

            changePassword: false,
            verfy_Dailog: false,
            owners: {
                password_confirmation: "",
                password: "",
                verfy_code: ""
            },
            error_msg: [],
            show1: false,
            show2: false,
            user: {
                phone: '',
            },
            loading: false,
            phoneRules: [
                (v) => !!v || 'يجب ادخال رقم الهاتف',
                (v) => v.length > 10 && v.length < 12 || 'يجب ادخال رقم هاتف صحيح',
                (v) => /^\d+$/.test(v) || 'يجب ادخال رقم هاتف صحيح',

            ],
            verfyRules: [
                (v) => !!v || 'يجب ادخال كود التفعيل',
                (v) => (v.length == 6) || ' يجب ان يكون كود التفعيل 6 ارقام',
                (v) => /^\d+$/.test(v) || 'ارقام فقط'

            ],

            passwordRules: [
                v => !!v || 'يجب ادخال الباسورد',
                (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
            ],
            passwordConfirmationRules: [
                v => !!v || 'يجب ادخال الباسورد',
                (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
                (v) => /^.*(?=.{3,})(?=.*[a-zA-Z])/.test(v) || 'يجب ان يحتوي على حروف وارقام',

            ],


        }),

        mounted() {

        },


        methods: {



            changesPassword() {
                if (this.$refs.form_signin.validate()) {
                    this.loading = true;

                    axios({
                            method: 'post',
                            url: "/auth/v2/forgotPassword/changePassword",
                            data: {
                                user_phone: "964" + parseInt(this.user.phone),
                                password_confirmation: this.owners.password_confirmation,
                                password: this.owners.password,
                                otpNumber: this.owners.verfy_code

                            },
                            headers: {

                            }
                        })
                        .then(response => {
                            response
                            this.$swal('', "تم تغير الباسورد بنجاح",
                                'success')
                            this.$router.push({
                                name: "LoginOwner"
                            })

                        })
                        .catch(error => {

                                if (error.response) {
                                    this.error_msg = [];
                                    if (error.response.data.data.password ==
                                        "The password confirmation does not match.") {

                                        this.error_msg.push('الباسوردان غير متاطبقان');

                                    }


                                    if (error.response.data.data ==
                                        "otp invalid") {

                                        this.error_msg.push('رمز التحقق غير صحيح');

                                    }


                                }
                            }


                        )

                        .finally(aa => {
                            aa,
                            this.loading = false;

                        });



                }

            },



            submit() {


                if (this.$refs.form_signin.validate()) {
                    this.loading = true;

                    axios({
                            method: 'post',
                            url: "/auth/v2/forgotPassword/checkPhone",
                            data: {
                                user_phone: "964" + parseInt(this.user.phone),

                            },
                            headers: {

                            }
                        })
                        .then(response => {
                            response
                            this.changePassword = true;
                        })
                        .catch(error => {

                                if (error.response) {
                                    this.error_msg = [];
                                    if (error.response.data.data ==
                                        "Account Not Found") {
                                        this.error_msg.push('رقم الهاتف غير مسجل');

                                    }
                                }
                            }


                        )

                        .finally(aa => {
                            aa,
                            this.loading = false;

                        });



                }

            },



        },
        components: {

        }
    }
</script>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>